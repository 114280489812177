import React from 'react';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD9I3Tt5M8RUCkFQgK2iDuzy44l91EDlns",
  authDomain: "torenuk-66ba4.firebaseapp.com",
  projectId: "torenuk-66ba4",
  storageBucket: "torenuk-66ba4.appspot.com",
  messagingSenderId: "668876123939",
  appId: "1:668876123939:web:555c7b5783340a31cea696",
  measurementId: "G-LBYMKPXGDP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <div className="App">
      <h1>TorenUK</h1>
    </div>
  );
}

export default App;
